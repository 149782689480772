@import "colors.css";
@import "sizes.css";

.hero {
  display: grid;
  place-items: center;
  /* width: 100%;
  height: 100%; */
  margin-top: var(--s-400);
}

.avatar {
  border-radius: 5%;
  aspect-ratio: 1/1;
  overflow: hidden;
  width: fit-content;
  max-width: 196px;
}

.avatar img {
  width: 100%;
  height:100%;
}

.name {
  display: flex;
  flex-direction: column;
  font-family: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: calc(var(--f-300)*1.4);
  gap: 0;
  /* margin-top: var(--s-400); */
}

.firstName {
  text-transform: lowercase;
   padding: 21px;
  font-weight: 600;
  line-height: var(--l-100);
}

.lastName {
  text-transform: uppercase;
  color: var(--primary);
  font-weight: 700;
  line-height: var(--l-200);
}

.socials {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  /* margin-bottom: var(--s-600); */
}

.subtitle {
  padding:0 21px;
  color: var(--neutral-600);
  font-size: var(--f-300);
 
}
.subtitle span{
 /* padding-top:5px; */
 
}
.links {
  display: flex;
  align-items: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 0;
  gap: var(--s-200);
}

.links li {
  padding: var(--s-100);
  border-radius: 4px;
  background-color: var(--neutral-350);
}

.links a {
  display: inline-block;
  color: var(--neutral-500);
}

.links a:active,
.links a:hover {
  color: var(--primary);
}

.contacts {

  display: flex;
  justify-content: space-between;
  border-top: 2px solid var(--neutral-400);
  padding-top: var(--s-250) ;
  padding-bottom: var(--s-200) ;
}

.contacts li,
.contacts a {
  display: flex;
  flex-direction: column;
  font-size: var(--f-100);
  text-decoration: none;
  color: calc(var(--neutral-700) *111);
}

.contacts h4 {
margin: 5px 0;
  color: calc(var(--neutral-700) *111);
  font-weight: 800;
  font-size:calc(var(--f-100)*1.15);
}

.contacts span {
  width:78%;
  color: var(--neutral-600);
}
