@import "colors.css";
@import "sizes.css";

.wrapper {
  width: 100%;
  padding-left: var(--s-400);
}

.expertise {
  display: grid;
  counter-reset: expertise;
  list-style-position: outside;
  margin: 0;
  padding: 0;
  position: relative;
  gap: var(--s-300) var(--s-500);
}

.expertise li {
  counter-increment: expertise;
}

.expertise li::marker {
  content: "0" counter(expertise) " ";
  color: var(--primary);
  font-size: var(--f-200);
  text-transform: uppercase;
  font-weight: 600;
}

.expertise h3 {
  text-transform: uppercase;
  color: var(--neutral-700);
  font-size: var(--f-200);
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.expertise p {
  font-size: var(--f-100) !important;
  font-weight: 500 !important;
}

@media screen and (min-width: 768px) {
  .expertise {
    grid-template-columns: repeat(2, 1fr);
    gap: var(--s-300) var(--s-500);
  }
}
