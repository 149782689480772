.job-role-item {
  display: inline-block;
  padding: .75rem 1rem;
  border-radius: .25;
  background-color: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, .05);
  text-align: center;
  font-size: .875rem;
  border: 1px solid rgba(0, 0, 0, .05);
  transition: box-shadow 0.15s ease-in-out, background-color .15s ease-in-out, color .15s ease-in-out;
}

.job-role-item:hover {
  box-shadow: none;
  color: #F89220;
  /* border-color: transparent; */
}

.job-role-item.active {
  background-color: #F89220;
  color: #fff;
  box-shadow: none;
  border-color: transparent;
}