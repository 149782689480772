@import "colors.css";
@import "sizes.css";

.section {
  --odd-color: var(--neutral-300);
  --even-color: var(--neutral-300);

  display: grid;
}

.section .header {
  width: 100%;
  height: 100%;
  /* padding: var(--s-400); */
  text-align: left;
  border-top: 1px solid var(--neutral-350);
  border-bottom: 1px solid var(--neutral-350);
  position: relative; 
  z-index: 2;
}

.section .header h2 {
  color: var(--neutral-700);
  font-size: var(--f-200);
  line-height: var(--l-100);
  text-transform: uppercase;
  font-weight: 600;
}

.section .header p {
  color: var(--neutral-600);
  line-height: var(--l-100);
  font-weight: 400;
}

.section:nth-child(even) .header {
  background-color: var(--even-color);
}

.section:nth-child(odd) .header {
  background-color: var(--odd-color);
}

.section .header[hero="true"] {
  background-color: var(--neutral-50);
  border: none;
  padding: 0;
}

.section .content {
   --content-pl: var(--s-400);  
  background-color: var(--neutral-50);
  width: 100%;
  height: 100%;
  /* padding: var(--s-400); */
  position: relative;
}

.section .content p {
  color: var(--neutral-600);
  font-size: var(--f-200);  
  font-weight: 300;
}

/* .section:nth-child(even) .content {
  /* background-color: var(--neutral-100);  
} */

@media screen and (min-width: 1024px) {
  .section {
    grid-template-columns: 3fr 11fr;

  }

  .section .header {
    text-align: right;
    border: none;
    /* padding: var(--s-500) var(--s-500) var(--s-300); */
  }

  .section .header[hero="true"] {
    background-color: var(--odd-color);
  }
  .content input{
width:40vw;

  }
  
  .section .content {
     --content-pl: var(--s-500);

    /* padding: var(--s-500) var(--s-500) var(--s-300); */
  }
}
