@import "../CurriculumDetailPage/css/sizes.css";
@import "../CurriculumDetailPage/css/colors.css";

.item {
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: var(--s-400);
  text-decoration: none;
  background-color: var(--neutral-100);
}

.picture {
  aspect-ratio: 1/1;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.picture img {
  width: 100%;
  height: auto;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info h3 {
  color: var(--primary);
  text-transform: uppercase;
  margin: 0;
}

.info p {
  font-weight: 600;
  color: var(--neutral-700);
  margin: 0;
}

.info span {
  font-weight: 400;
  color: var(--neutral-600);
  margin: 0;
}

.info .separator {
  width: 40px;
  height: 5px;
  background-color: var(--neutral-500);
  margin-top: var(--s-200);
}

.info .separator hr {
  width: 0;
  height: 5px;
  border: none;
  background-color: var(--primary);
  margin: 0;
  transition: width 500ms ease;
}

.item:hover .separator hr {
  width: 100%;
}
