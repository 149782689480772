@import "colors.css";
@import "sizes.css";

.hero {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}

.avatar {
  border-radius: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
  width: fit-content;
  max-width: 196px;
}

.avatar img {
  width: 100%;
  height: auto;
}

.name {
  display: flex;
  flex-direction: column;
  font-family: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: var(--f-400);
  gap: 0;
  margin-top: var(--s-400);
}

.firstName {
  text-transform: lowercase;
  color: var(--neutral-600);
  font-weight: 300;
  line-height: var(--l-100);
}

.lastName {
  text-transform: uppercase;
  color: var(--primary);
  font-weight: 700;
  line-height: var(--l-200);
}

.socials {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: var(--s-600);
}

.subtitle {
  color: var(--neutral-600);
  font-size: var(--f-300);
  font-weight: 300;
}

.links {
  display: flex;
  align-items: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 0;
  gap: var(--s-200);
}

.links li {
  padding: var(--s-100);
  border-radius: 4px;
  background-color: var(--neutral-350);
}

.links a {
  display: inline-block;
  color: var(--neutral-500);
}

.links a:active,
.links a:hover {
  color: var(--primary);
}

.contacts {
  display: flex;
  justify-content: space-between;
  border-top: 2px solid var(--neutral-400);
  padding: var(--s-400) 0;
}

.contacts li,
.contacts a {
  display: flex;
  flex-direction: column;
  font-size: var(--f-100);
  text-decoration: none;
}

.contacts h4 {
  margin: 0;
  color: var(--neutral-700);
  font-weight: 600;
}

.contacts span {
  color: var(--neutral-600);
}
