:root {
  --neutral-50: #fff;
  --neutral-100: #fcfcfc;
  --neutral-200: #fbfbfb;
  --neutral-300: #f8f8f8;
  --neutral-350: #ededed;
  --neutral-400: #ccc;
  --neutral-500: #c6c6c6;
  --neutral-600: #939393;
  --neutral-700: #727272;
  --neutral-800: #3a3a3a;

  --primary: #f8bb10;
}
