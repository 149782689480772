@import "sizes.css";
@import "colors.css";

.list {
  display: grid;
  padding: 0;
  list-style: none;
  gap: var(--s-400);
}

.award::before {
  content: "";
  display: block;
  width: 30%;
  height: 5px;
  background-color: var(--primary);
  margin-bottom: var(--f-100);
}

.title {
  font-size: var(--f-100);
  font-weight: 700;
  text-transform: uppercase;
  color: var(--neutral-700);
  margin: var(--s-200) 0;
}

.award p.description {
  font-size: var(--f-100);
  font-weight: 500;
  margin: 0;
}

@media screen and (min-width: 600px) {
  .list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 760px) {
  .list {
    grid-template-columns: repeat(3, 1fr);
  }
}
