.styled-scroll::-webkit-scrollbar {
  width: 10px;
}

.styled-scroll::-webkit-scrollbar-thumb {
  background-color: #cad3da;
}

.styled-scroll::-webkit-scrollbar-track {
  border: 1px solid rgba(0,0,0,0.1);
}

.progress-bar {
  width: 90%;
  height: 20px;
  background-color: #dfe5e9a1;
  border-radius: 10px;
  position: relative;
}

.progress {
  top: 0;
  left: 0;
  height: 100%;
  position: absolute;
  background-color: #DDAE98;
  border-radius: 10px;
  transition: width 0.5s ease-in-out;
}