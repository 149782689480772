@import "sizes.css";
@import "colors.css";

.list {
  display: flex;
  flex-direction: column;
  gap: var(--s-400);
  padding-left: var(--s-400);
  list-style: none;
}

.marker {
  --disc: 10px;
  --line: 1px;
  --pad: var(--content-pl);
  --line-left: calc((var(--disc) - var(--line)) / 2 + var(--pad));
  width: var(--line);
  height: 100%;
  background-color: var(--neutral-500);
  position: absolute;
  left: var(--line-left);
}

.marker::after {
  content: "";
  position: absolute;
  left: calc((var(--disc) / 2) * -1);
  width: var(--disc);
  height: var(--disc);
  border-radius: 50%;
  background-color: var(--primary);
  box-shadow: 0 0 0 5px var(--neutral-50);
  z-index: 1;
}

.wrapper {
  display: grid;
}

.intro p.date {
  font-size: var(--f-50);
  opacity: 0.6;
  font-weight: 500;
  margin: 0 0 var(--s-200) 0;
}

.intro h3 {
  font-size: var(--f-100);
  margin: 0 0 var(--s-200) 0;
  color: var(--neutral-600);
}

.intro p.role {
  font-size: var(--f-100);
  font-weight: 500;
  margin: 0 0 var(--s-200) 0;
}

.intro p.address {
  font-size: var(--f-50);
  opacity: 0.6;
  font-weight: 500;
  margin: 0 0 var(--s-300) 0;
}

.wrapper p.description {
  font-size: var(--f-100);
  margin: 0;
}

@media screen and (min-width: 760px) {
  .wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
