.ft-slick__dots--custom {
    height: 10px;
    width: 10px;
    background-color: #E9E9E961;
    position: relative;
  }

  .slick-dots {
    bottom: 0;
  }

  .slick-dots li {
    left: 5em;
    float: left;
    bottom: 15px;
    width: 10px;
    height: 10px;
    margin: 0 2px;
    background-color: #E9E9E961;
    transition: width 0.3s ease-in-out;
  }

  .slick-dots .slick-active {
    width: 10px;
    transition: width 0.3s ease-in-out;
  }

  .slick-dots .slick-active .ft-slick__dots--custom {
    overflow: hidden;
    background-color: #F7931E;
  }