@import "sizes.css";
@import "colors.css";

.list {
  display: grid;
  padding: 0;
  list-style: none;
  gap: var(--s-400);
  grid-template-columns: repeat(auto-fill, minmax(min(300px, 50%), 1fr));
}

.project {
  width: 100%;
  height: auto;
  aspect-ratio: 3/2;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 0;
  position: relative;
  z-index: 0;
}

.wrapper {
  position: relative;
  padding: var(--s-200);
  opacity: 0;
  transition: 300ms ease-in;
  width: 100%;
  height: 100%;
}

.wrapper:hover {
  opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--neutral-50);
  opacity: 0.6;
  z-index: 1;
}

.title {
  position: relative;
  z-index: 2;
  margin: 0;
  text-transform: uppercase;
  font-size: max(var(--f-100), 1.3vw);
  color: var(--neutral-800);
  margin-bottom: var(--s-200);
}

.separator {
  width: 30%;
  height: 4px;
  background-color: var(--neutral-800);
  border: none;
  position: relative;
  z-index: 2;
  margin: 0;
}

.wrapper p.description {
  position: relative;
  z-index: 2;
  font-size: max(var(--f-100), 1.2vw);
  color: var(--neutral-800);
  font-weight: 500;
}

@media screen and (min-width: 406px) {
  .wrapper {
    padding: var(--s-400);
  }
}
