@import "colors.css";
@import "sizes.css";

.list {
  display: grid;
  list-style: none;
  padding: 0;
  gap: var(--s-400);
}

.profile {
  display: grid;
  grid-template-columns: 40px 1fr;
  text-decoration: none;
}

.icon svg {
  fill: var(--neutral-400);
  font-size: var(--f-200);
}

.name {
  text-transform: uppercase;
  color: var(--primary);
  font-size: var(--f-100);
  margin: 0;
}

.profile p.description {
  grid-column-start: 2;
  margin: 0;
  font-size: var(--f-100);
  font-weight: 500;
}

@media screen and (min-width: 600px) {
  .list {
    grid-template-columns: repeat(2, 1fr);
  }
}
