:root {
  --f-50: 0.7rem;
  --f-100: 0.9rem;
  --f-200: 1.2rem;
  --f-300: 1.3rem;
  --f-400: 2.8rem;

  --s-100: 0.2em;
  --s-200: 0.4em;
  --s-300: 1.6em;
  --s-400: 1.7em;
  --s-500: 4.3em;
  --s-600: 5em;

  --l-100: 0.8;
  --l-200: 1.2;
  --l-300: 1.4;
}
