.notification-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  z-index: 99999999999999999999999999;
  opacity: 0;
  pointer-events: none;
}

.notification-container.active {
  opacity: 1;
  pointer-events: all;
}

.notification-content {
  position: fixed;
  top: 0;
  right: -24rem;
  width: 380px;
  max-width: 90%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 99999999999999999999999999;
  background-color: white;
  transition: right 0.5s ease-in-out;
}

.notification-container.active .notification-content {
  right: 0;
}

.notification-top {
  display: flex;
  align-items: center;
  padding: 0.65rem 0.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.notification-top > span {
  font-size: 1rem;
  font-weight: bold;
}

.item-circle,
.notification-more-btn {
  width: 40px;
  height: 40px;
  aspect-ratio: 1/1;
  display: flex;
}

button.item-circle {
  top: 50%;
  z-index: 111;
  right: -10.75rem;
  position: absolute;
  transform: translateY(-50%);
  transition: right 0.45s ease-in-out
}

.notification-item {
  position: relative;
}

.notification-item:hover button {
  right: 0.75rem;
}

.item-circle {
  border-radius: 50%;
}

.notification-more-btn {
  top: 50%;
  z-index: 111;
  right: 0.75rem;
  position: absolute;
  transform: translateY(-50%);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}