@import "sizes.css";

.list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: var(--s-300);
  padding: 0;
}

.link {
  opacity: 0.6;
}

.link:hover {
  opacity: 1;
}
