.card {
  width: 100%;
  min-height: 2px;
  border-radius: 0.25rem;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  position: relative;
}

.amazing-shadow {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.045);
}

.text-el-1, .text-el-2 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.text-el-1 {
  line-clamp: 1;
}

.text-el-2 {
  line-clamp: 2;
}


.banner {
  display: flex;
  align-items: center;
  gap: 5px;
}

.banner-colored {
  color: #F7931E;
  font-weight: bold;
}


.p-input {
  width: 100%;
  outline: none;
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  background-color: white;
  padding: 0.475rem 1rem;
  transition: box-shadow 0.25s ease-in-out, border-color 0.25s ease-in-out;
}

.p-input:focus {
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.07);
}

.icon {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  left: 1.25rem;
}

.pl-1 {
  padding-left: 1.5rem;
}

[class*="btn"] {
  outline: none;
  border: 1px solid transparent;
  padding: 0.475rem 1rem;
  display: inline-block;
  text-align: center;
  border-radius: 0.25rem;
  cursor: pointer;
  margin: 0;
  /*	font-size: 14px;*/
  font-weight: 600;
  transition: box-shadow 0.25s ease-in-out, border-color 0.25s ease-in-out;
}

[class*="btn"]:focus {
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.flex {
  display: flex;
  align-items: center;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.feedback {
  color: rgba(0, 0, 0, 0.7);
  font-size: 12.5px;
  background-color: white;
  font-weight: bold;
  padding: 0.475rem 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem 0 0 0.25rem;
}

.feedback+.p-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-default {
  border-color: rgba(0, 0, 0, 0.15);
  background-color: white;
  color: rgba(0, 0, 0, 0.65);
}

.btn-white-primary {
  color: #F7931E;
  background-color: white;
  border-color: rgba(0, 0, 0, 0.1);
}

.btn-primary {
  color: white;
  background-color: #F7931E;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.wrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 2rem;
}

.card-item {
  width: 100%;
  border-radius: 0.4rem;
  padding: 1rem;
  display: flex;
  min-height: 80px;
  margin-top: 1rem;
  align-items: flex-start;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.card-img {
  width: 38px;
  height: 38px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: rgb(209, 219, 235);
  color: white;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-img img {
  border-radius: 50%;
}

.font-bold {
  display: block;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
}

.text-gray-500 {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.5);
  display: block;
  margin-bottom: 5px;
}

.ratings {
  display: flex;
  align-items: center;
  color: #F7931E;
  margin-bottom: 5px;
  font-size: 1.05rem;
}

.card-action {
  color: #F7931E;
  display: inline-block;
  padding: 0.35rem 0.5rem;
  border-radius: 0.25rem;
  text-decoration: none;
  font-size: 13px;
  font-weight: bold;
  background-color: rgb(209, 219, 235);
}

/*demo.buesimples.net/gestao*/



.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 575.98px) {

  .container,
  .container-sm {
    max-width: 543.98px;
  }
}

@media (min-width: 767.98px) {

  .container,
  .container-sm,
  .container-md {
    max-width: 735.98px;
  }
}

@media (min-width: 991.98px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 959.98px;
  }
}

@media (min-width: 1199.98px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1167.98px;
  }
}

@media (min-width: 1399.98px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1367.98px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.no-spacing {
  margin-left: 0;
  margin-right: 0;
}

.no-spacing>.col,
.no-spacing>[class*=col] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.col,
.col-auto {
  position: relative;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-sm-auto,
.col-md-auto,
.col-lg-auto,
.col-xl-auto,
.col-auto {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  transition: 0.15s ease-in-out;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1>* {
  flex: 0 0 100%;
  width: 100%;
}

.row-cols-2>* {
  flex: 0 0 50%;
  width: 50%;
}

.row-cols-3>* {
  flex: 0 0 33.3333333333%;
  width: 33.3333333333%;
}

.row-cols-4>* {
  flex: 0 0 25%;
  width: 25%;
}

.row-cols-5>* {
  flex: 0 0 20%;
  width: 20%;
}

.row-cols-6>* {
  flex: 0 0 16.6666666667%;
  width: 16.6666666667%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.offset-1 {
  max-width: 8.3333333333%;
}

.offset-2 {
  max-width: 16.6666666667%;
}

.offset-3 {
  max-width: 25%;
}

.offset-4 {
  max-width: 33.3333333333%;
}

.offset-5 {
  max-width: 41.6666666667%;
}

.offset-6 {
  max-width: 50%;
}

.offset-7 {
  max-width: 58.3333333333%;
}

.offset-8 {
  max-width: 66.6666666667%;
}

.offset-9 {
  max-width: 75%;
}

.offset-10 {
  max-width: 83.3333333333%;
}

.offset-11 {
  max-width: 91.6666666667%;
}

.offset-12 {
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order--1 {
  order: -1;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

@media (min-width: 575.98px) {
  .no-spacing-sm {
    margin-left: 0;
    margin-right: 0;
  }

  .no-spacing-sm>.col,
  .no-spacing-sm>[class*=col] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .col-sm,
  .col-sm-auto {
    position: relative;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  .row-sm-cols-1>* {
    flex: 0 0 100%;
    width: 100%;
  }

  .row-sm-cols-2>* {
    flex: 0 0 50%;
    width: 50%;
  }

  .row-sm-cols-3>* {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
  }

  .row-sm-cols-4>* {
    flex: 0 0 25%;
    width: 25%;
  }

  .row-sm-cols-5>* {
    flex: 0 0 20%;
    width: 20%;
  }

  .row-sm-cols-6>* {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .offset-sm-1 {
    max-width: 8.3333333333%;
  }

  .offset-sm-2 {
    max-width: 16.6666666667%;
  }

  .offset-sm-3 {
    max-width: 25%;
  }

  .offset-sm-4 {
    max-width: 33.3333333333%;
  }

  .offset-sm-5 {
    max-width: 41.6666666667%;
  }

  .offset-sm-6 {
    max-width: 50%;
  }

  .offset-sm-7 {
    max-width: 58.3333333333%;
  }

  .offset-sm-8 {
    max-width: 66.6666666667%;
  }

  .offset-sm-9 {
    max-width: 75%;
  }

  .offset-sm-10 {
    max-width: 83.3333333333%;
  }

  .offset-sm-11 {
    max-width: 91.6666666667%;
  }

  .offset-sm-12 {
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm--1 {
    order: -1;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }
}

@media (min-width: 767.98px) {
  .no-spacing-md {
    margin-left: 0;
    margin-right: 0;
  }

  .no-spacing-md>.col,
  .no-spacing-md>[class*=col] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .col-md,
  .col-md-auto {
    position: relative;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  .row-md-cols-1>* {
    flex: 0 0 100%;
    width: 100%;
  }

  .row-md-cols-2>* {
    flex: 0 0 50%;
    width: 50%;
  }

  .row-md-cols-3>* {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
  }

  .row-md-cols-4>* {
    flex: 0 0 25%;
    width: 25%;
  }

  .row-md-cols-5>* {
    flex: 0 0 20%;
    width: 20%;
  }

  .row-md-cols-6>* {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .offset-md-1 {
    max-width: 8.3333333333%;
  }

  .offset-md-2 {
    max-width: 16.6666666667%;
  }

  .offset-md-3 {
    max-width: 25%;
  }

  .offset-md-4 {
    max-width: 33.3333333333%;
  }

  .offset-md-5 {
    max-width: 41.6666666667%;
  }

  .offset-md-6 {
    max-width: 50%;
  }

  .offset-md-7 {
    max-width: 58.3333333333%;
  }

  .offset-md-8 {
    max-width: 66.6666666667%;
  }

  .offset-md-9 {
    max-width: 75%;
  }

  .offset-md-10 {
    max-width: 83.3333333333%;
  }

  .offset-md-11 {
    max-width: 91.6666666667%;
  }

  .offset-md-12 {
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md--1 {
    order: -1;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }
}

@media (min-width: 991.98px) {
  .no-spacing-lg {
    margin-left: 0;
    margin-right: 0;
  }

  .no-spacing-lg>.col,
  .no-spacing-lg>[class*=col] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .col-lg,
  .col-lg-auto {
    position: relative;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  .row-lg-cols-1>* {
    flex: 0 0 100%;
    width: 100%;
  }

  .row-lg-cols-2>* {
    flex: 0 0 50%;
    width: 50%;
  }

  .row-lg-cols-3>* {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
  }

  .row-lg-cols-4>* {
    flex: 0 0 25%;
    width: 25%;
  }

  .row-lg-cols-5>* {
    flex: 0 0 20%;
    width: 20%;
  }

  .row-lg-cols-6>* {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .offset-lg-1 {
    max-width: 8.3333333333%;
  }

  .offset-lg-2 {
    max-width: 16.6666666667%;
  }

  .offset-lg-3 {
    max-width: 25%;
  }

  .offset-lg-4 {
    max-width: 33.3333333333%;
  }

  .offset-lg-5 {
    max-width: 41.6666666667%;
  }

  .offset-lg-6 {
    max-width: 50%;
  }

  .offset-lg-7 {
    max-width: 58.3333333333%;
  }

  .offset-lg-8 {
    max-width: 66.6666666667%;
  }

  .offset-lg-9 {
    max-width: 75%;
  }

  .offset-lg-10 {
    max-width: 83.3333333333%;
  }

  .offset-lg-11 {
    max-width: 91.6666666667%;
  }

  .offset-lg-12 {
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg--1 {
    order: -1;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }
}

@media (min-width: 1199.98px) {
  .no-spacing-xl {
    margin-left: 0;
    margin-right: 0;
  }

  .no-spacing-xl>.col,
  .no-spacing-xl>[class*=col] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .col-xl,
  .col-xl-auto {
    position: relative;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  .row-xl-cols-1>* {
    flex: 0 0 100%;
    width: 100%;
  }

  .row-xl-cols-2>* {
    flex: 0 0 50%;
    width: 50%;
  }

  .row-xl-cols-3>* {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
  }

  .row-xl-cols-4>* {
    flex: 0 0 25%;
    width: 25%;
  }

  .row-xl-cols-5>* {
    flex: 0 0 20%;
    width: 20%;
  }

  .row-xl-cols-6>* {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .offset-xl-1 {
    max-width: 8.3333333333%;
  }

  .offset-xl-2 {
    max-width: 16.6666666667%;
  }

  .offset-xl-3 {
    max-width: 25%;
  }

  .offset-xl-4 {
    max-width: 33.3333333333%;
  }

  .offset-xl-5 {
    max-width: 41.6666666667%;
  }

  .offset-xl-6 {
    max-width: 50%;
  }

  .offset-xl-7 {
    max-width: 58.3333333333%;
  }

  .offset-xl-8 {
    max-width: 66.6666666667%;
  }

  .offset-xl-9 {
    max-width: 75%;
  }

  .offset-xl-10 {
    max-width: 83.3333333333%;
  }

  .offset-xl-11 {
    max-width: 91.6666666667%;
  }

  .offset-xl-12 {
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl--1 {
    order: -1;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }
}

@media (min-width: 1399.98px) {
  .no-spacing-xxl {
    margin-left: 0;
    margin-right: 0;
  }

  .no-spacing-xxl>.col,
  .no-spacing-xxl>[class*=col] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .col-xxl,
  .col-xxl-auto {
    position: relative;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  .row-xxl-cols-1>* {
    flex: 0 0 100%;
    width: 100%;
  }

  .row-xxl-cols-2>* {
    flex: 0 0 50%;
    width: 50%;
  }

  .row-xxl-cols-3>* {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
  }

  .row-xxl-cols-4>* {
    flex: 0 0 25%;
    width: 25%;
  }

  .row-xxl-cols-5>* {
    flex: 0 0 20%;
    width: 20%;
  }

  .row-xxl-cols-6>* {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
  }

  .col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .offset-xxl-1 {
    max-width: 8.3333333333%;
  }

  .offset-xxl-2 {
    max-width: 16.6666666667%;
  }

  .offset-xxl-3 {
    max-width: 25%;
  }

  .offset-xxl-4 {
    max-width: 33.3333333333%;
  }

  .offset-xxl-5 {
    max-width: 41.6666666667%;
  }

  .offset-xxl-6 {
    max-width: 50%;
  }

  .offset-xxl-7 {
    max-width: 58.3333333333%;
  }

  .offset-xxl-8 {
    max-width: 66.6666666667%;
  }

  .offset-xxl-9 {
    max-width: 75%;
  }

  .offset-xxl-10 {
    max-width: 83.3333333333%;
  }

  .offset-xxl-11 {
    max-width: 91.6666666667%;
  }

  .offset-xxl-12 {
    max-width: 100%;
  }

  .order-xxl-first {
    order: -1;
  }

  .order-xxl-last {
    order: 13;
  }

  .order-xxl--1 {
    order: -1;
  }

  .order-xxl-0 {
    order: 0;
  }

  .order-xxl-1 {
    order: 1;
  }

  .order-xxl-2 {
    order: 2;
  }

  .order-xxl-3 {
    order: 3;
  }

  .order-xxl-4 {
    order: 4;
  }

  .order-xxl-5 {
    order: 5;
  }

  .order-xxl-6 {
    order: 6;
  }

  .order-xxl-7 {
    order: 7;
  }

  .order-xxl-8 {
    order: 8;
  }

  .order-xxl-9 {
    order: 9;
  }

  .order-xxl-10 {
    order: 10;
  }

  .order-xxl-11 {
    order: 11;
  }

  .order-xxl-12 {
    order: 12;
  }
}

