@import "sizes.css";
@import "colors.css";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99;
  display: grid;
  place-items: center;
}

.popup {
  --h: 500px;
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  gap: 1vw;
  width: 100%;
  height: 100%;
  max-height: var(--h);
  padding: var(--s-400);
}

.left,
.right {
  font-size: min(8vw, var(--f-400));
  color: var(--neutral-50);
  font-weight: 600;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.photo {
  aspect-ratio: 3/2;
  height: auto;
  width: min(80%, 800px);
  justify-self: center;
  align-self: center;
}

.photo img {
  width: 100%;
  height: auto;
}

.popup p.caption {
  grid-column: 2/3;
  justify-self: center;
  color: var(--neutral-50);
  font-weight: 600;
}
