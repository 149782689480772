@import "colors.css";

.skills {
  display: grid;
  list-style-type: none;
  padding: 0;
  gap: var(--s-300);
}

.skills .describe {
  display: flex;
  gap: var(--s-200);
  align-items: flex-end;
  font-weight: 600;
  margin-bottom: var(--s-200);
}

.skills .percentage,
.skills .label {
  color: var(--primary);
  text-transform: uppercase;
  font-size: var(--f-100);
}

.skills small {
  font-size: var(--f-100);
}

.skills .label {
  color: var(--neutral-700);
}

.skills .bar {
  background-color: var(--neutral-350);
  width: 100%;
  height: 5px;
  position: relative;
  --percent: 0%;
}

.skills .bar::after {
  content: "";
  height: 100%;
  width: var(--percent);
  background-color: var(--primary);
  position: absolute;
  transition: width 1.4s ease-in-out 0.5s;
}

@media screen and (min-width: 600px) {
  .skills {
    grid-template-columns: repeat(2, 1fr);
  }
  .skills .label,
  .skills .percentage {
    font-size: var(--f-200);
  }
}
