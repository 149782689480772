* {
  box-sizing: border-box !important;
  /* Inclui padding e border no tamanho total */
}

:root {
  --accent: #F89220;
}

.controls-bar {
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.default-color {
  color: var(--accent)
}

.layoutDoc {
  width: 210mm;
  min-height: 297mm;
  margin: 0 auto;
  background-color: #2f272d;
  position: relative;
  display: flex;
  padding-top: 0.5rem;
  flex-direction: column;
}

.layoutDoc-header {
  height: 20%;
  position: relative;
}

.layoutDoc-header-contain {
  height: 100%;
  max-height: 100%;
  position: relative;
  padding: 0.5rem 1rem;
}

.layout-header-img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: center;
  /* width: 30% !important;
  max-width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  object-fit: contain;
  align-self: flex-start; */
}

.layoutDoc-body {
  flex: 1;
  min-height: 80%;
  max-height: 80%;
  position: relative;
}

@media print {
  .layoutDoc {
    width: 210mm;
    height: 297mm;
    margin: 0;
    padding: 0mm;
    /* margem interna para evitar cortes */
    box-sizing: border-box;
    background-color: #2F272D !important;
  }
}


.layoutDoc>* {
  width: 100%;
}

.curv-header>* {
  width: 100%;
}

.curv-owner {
  font-size: 1.5rem;
  font-weight: 900;
  text-transform: uppercase;
}

.curv-tiny-text {
  font-size: 0.85rem;
}

.curv-owner+span {
  font-size: 1.5rem;
  font-weight: 900;
  display: block;
}

.curv-rotate-link {
  transform: rotate(-45deg);
}

.curv-title {
  font-weight: 900;
  font-size: 1rem;
  display: flex !important;
  align-items: center !important;
  text-transform: uppercase;
  color: var(--accent);
}

.curv-inner-title {
  font-size: 1rem;
  font-weight: 800;
}

.curv-inner-title+span {
  font-size: 0.85rem;
  /* color: var(--accent); */
  color: var(--accent);
  font-weight: 700;
}

.institution-item {
  position: relative;
  box-sizing: border-box;
  height: 100%;
}

.institution-item::before {
  content: '';
  position: absolute;
  top: 1.25rem;
  /* Faz a borda começar no topo do elemento */
  bottom: 0;
  /* Faz a borda alcançar o fim */
  right: 0;
  width: 1.75px;
  background-color: rgba(0, 0, 0, 0.1);
  /* Cor da borda */
}

.institution-item::after {
  content: '';
  top: 0.5rem;
  right: -2.8px;
  width: 8px;
  height: 8px;
  position: absolute;
  border-radius: 50%;
  background-color: var(--accent);
}

.institution-item>* {
  width: 100%;
  align-items: flex-start !important;
}

.institution-item> :first-child {
  font-size: 14px;
}

.institution-item> :nth-child(2) {
  font-size: 12px;
}

ul {
  font-size: 14px;
  list-style: disc;
  padding-bottom: 0.85rem;
}

ul>* {
  width: 100%;
}

.curv-skill-item {
  padding: 0.45rem 0.75rem;
  border-radius: 0.15rem;
  background-color: aliceblue;
  font-size: 13px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.curv-bubbles .bubble {
  width: 12px;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  background-color: rgb(216, 222, 228);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.curv-bubbles .bubble.active {
  background-color: var(--accent);
}


@media print {
  body * {
    visibility: hidden;
  }

  @page {
    size: A4;
    /* Ou outro tamanho desejado */
    margin: 0;
  }

  body {
    width: 210mm;
    height: 297mm;
    margin: 0;
    /* Remove margens extras */
  }

  .curv-title {
    display: flex !important;
    align-items: center !important;
  }

  .curv-title>* {
    line-height: 40px;
  }

  .layoutDoc,
  .layoutDoc * {
    visibility: visible;
  }

  .layoutDoc {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.augmented-top {
  margin-top: 0;
}

.augmented-top-alt {
  margin-top: 0rem;
  padding: 0 0.25rem !important;
}